import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Track from 'raisenow-analytics'
import useScript from 'react-script-hook'
const extraDonationAmount = 10
export const RaiseNowWidget = ({
  amount,
  config,
  donationItems,
  environment,
  labels,
  onPaymentSuccess,
  gAEcommerceItems
}) => {
  const [initRaisnow, setInitRaisenow] = useState(false)
  const [amountComputed, setAmountComputed] = useState(amount)
  const [showLoading, setShowLoading] = useState(true)
  const [loading, error] = useScript({
    src: 'https://tamaro.raisenow.com/srk-ch/latest/widget.js',
    checkForExisting: true,
    onload: () => setInitRaisenow(true)
  })
  const isDevelopment = environment === 'development'
  const addExtraDonationEventHandler = useCallback(({ api }) => {
    const selector = '#stored_extra_donation-widget'
    const extraDonation = document.querySelector(selector)
    const originalAmount = amount
    if (extraDonation) {
      extraDonation.addEventListener('click', () => {
        const isChecked = extraDonation.checked

        if (isChecked) {
          const calculatedAmount = originalAmount + extraDonationAmount
          const currentPaymentMethod = api?.paymentForm?.getPaymentMethod()
          setAmountComputed(calculatedAmount)
          api?.paymentForm?.setAmount(calculatedAmount)
          // sms gets deselected if payment method is above 100
          if (currentPaymentMethod === 'sms' && calculatedAmount > 100) {
            // we have to reselect the checkbox as a result a bug in tamaro
            rnw.tamaro.instance.paymentForm.data.stored_extra_donation = true
          }
        } else {
          setAmountComputed(originalAmount)
          api?.paymentForm?.setAmount(originalAmount)
        }
      })
    }
  }, [])

  const amountLabel = useMemo(() => {
    if (amountComputed) {
      return `${amountComputed}.– CHF`
    } else {
      return null
    }
  }, [amountComputed])
  const title =
    (amountLabel &&
      labels?.header_singular_label?.replace('${amount}', amountLabel)) ||
    null
  const getDonationItems = useMemo(() => {
    let items = []
    if (donationItems) {
      items = donationItems
        .filter((item) => item.quantity > 0)
        .map((item) => ({
          id: item.id,
          title: item.title,
          quantity: item.quantity,
          price: item.quantity * item.price
        }))
    }
    return items
  }, [])

  useEffect(() => {
    if (initRaisnow) {
      let gAEcommerceStepPaymentMethodTracked = false
      const campaignId = config?.raiseNow.campaign_id
      const track = new Track({
        list: campaignId
      })
      // payment success? Do not track
      if (donationItems) {
        track.onCheckout({
          step: 1,
          products: gAEcommerceItems
        })
      }
      let slots = {}
      const paymentWidgetBlocks = [
        'payment_payment_methods',
        'payment_profile',
        'payment_address'
      ]

      paymentWidgetBlocks.push('slot_1')
      slots = {
        slot_1: [
          {
            component: 'block',
            children: [
              {
                component: 'block_header',
                title: 'blocks.slot_1.title'
              },
              {
                component: 'block_content',
                children: [
                  {
                    component: 'field',
                    type: 'checkbox',
                    formName: 'payment_form',
                    name: 'stored_extra_donation',
                    text: 'payment_form.stored_extra_donation'
                  }
                ]
              }
            ]
          }
        ]
      }

      rnw?.tamaro?.runWidget('.paymentForm', {
        debug: isDevelopment,
        debugSlots: false,
        testMode: isDevelopment,
        paymentWidgetBlocks,
        forceShowBlocks: {
          payment_purposes: false,
          payment_amounts_and_intervals: false,
          payment_payment_methods: true,
          payment_profile: true,
          payment_address: true
        },
        showFooter: true,
        showSubmitButton: true,
        showRetryPaymentButton: true,
        showStoredCustomerEmailPermission: true,
        language: labels?.language_code,
        fallbackLanguage: 'de',
        paymentFormPrefill: {
          stored_customer_country: 'CH',
          stored_extra_donation: false,
          supporter: {
            metadata: {
              ga_ecommerce_items: JSON.stringify(gAEcommerceItems),
              donation_items: JSON.stringify(getDonationItems)
            }
          },
          // Add thank you mail config
          stored_donation_purpose_id: '7lk48vjL2AbWBZTEJX3H0y'
        },
        slots,
        purposes: ['p1'],
        purposeDetails: {
          p1: {
            stored_campaign_id: campaignId
          }
        },
        translations: {
          de: {
            purposes: {
              p1: '2 x Weihnachten'
            },
            organization_name: 'Schweizerisches Rotes Kreuz',
            organization_email: 'spenden@redcross.ch',
            blocks: {
              payment_profile: {
                email_permission_info_html: ''
              },
              slot_1: {
                title: 'Spezielle Weihnachtsfreude'
              },
              overlay_data_protection_policy: {
                text_html: `<section>
                <p>Die Übermittlung der Daten erfolgt verschlüsselt. Das Schweizerische Rote Kreuz speichert selbst keine Kartendaten, Ihre Zahlungsdaten laufen direkt über RaiseNow, einem von der Kartenindustrie (PCI DSS) zertifizierten Partner. Unsere Dienstleister dürfen die Informationen ausschliesslich zur Erfüllung ihrer Aufgaben nutzen und sind verpflichtet, die Datenschutzbestimmungen einzuhalten.</p>
                <p>Sie können Ihre Spende von Ihrem steuerbaren Einkommen abziehen. Spender erhalten per E-Mail eine Spendenbestätigung zu Handen der Steuerbehörden. Die Spenden gehen an %% trans(organization_name) %%.</p>
              </section>`
              }
            },
            payment_form: {
              stored_customer_email_permission:
                'Bitte halten Sie mich per E-Mail über Ihre Aktivitäten auf dem Laufenden',
              stored_extra_donation: labels?.extra_donation
            }
          },
          fr: {
            purposes: {
              p1: '2 x Noël'
            },
            blocks: {
              payment_profile: {
                email_permission_info_html: ''
              },
              slot_1: {
                title: 'Cadeau solidaire pour noël'
              }
            },
            organization_name: 'Croix-Rouge suisse',
            organization_email: 'don@redcross.ch',
            payment_form: {
              stored_customer_email_permission:
                'Veuillez me communiquer vos activités par e-mail'
            }
          },
          it: {
            purposes: {
              p1: '2 x Natale'
            },
            blocks: {
              payment_profile: {
                email_permission_info_html: ''
              },
              slot_1: {
                title: 'Un regalo di Natale'
              }
            },
            organization_name: 'Croce Rossa Svizzera',
            organization_email: 'donazione@redcross.ch',
            payment_form: {
              stored_customer_email_permission:
                'Vorrei ricevere aggiornamenti via e-mail sulle vostre attività.'
            }
          },
          en: {
            purposes: {
              p1: '2 x Christmas'
            },
            blocks: {
              payment_profile: {
                email_permission_info_html: ''
              },
              slot_1: {
                title: 'Special Christmas joy'
              }
            },
            organization_name: 'Swiss red cross',
            organization_email: 'donate@redcross.ch',
            payment_form: {
              stored_customer_email_permission:
                'Please keep me informed about your activities by e-mail'
            }
          }
        }
      })
      rnw.tamaro.events['afterCreate'].subscribe(({ data }) => {
        const { api } = data
        setShowLoading(false)
        if (amount > 0) {
          api?.paymentForm?.setAmount(amount)
          // try to get amount from rnw searchparams
        } else {
          const urlObject = new URL(window.location)
          const amountFromParams = urlObject.searchParams.get('rnw-amount')
          if (amountFromParams) {
            setAmountComputed(amountFromParams)
            api?.paymentForm?.setAmount(amountFromParams)
          }
        }
        setTimeout(() => {
          addExtraDonationEventHandler({ api })
        }, 200)
      })

      rnw.tamaro.events['afterRender'].subscribe(({ data }) => {
        // Click first entry point of address
        const paymentProfileBlock = document.querySelector(
          '.payment-profile-block'
        )
        const trackFunction = () => {
          track.ecommercePush({
            event: 'add_shipping_info',
            ecommerce: {
              items: gAEcommerceItems
            }
          })
        }
        if (paymentProfileBlock) {
          paymentProfileBlock.addEventListener('click', trackFunction, {
            once: true
          })
        }
      })
      rnw.tamaro.events['amountChanged'].subscribe(({ data }) => {
        const { api } = data
        const amount = api?.paymentForm?.getAmount()
        const currency = api?.paymentForm.getCurrency()

        track.push({
          event: 'raisenowEvent',
          raisenowEventName: 'raisenowAmountChanged',
          raisenowAmount: amount,
          raisenowCurrency: currency
        })
      })

      rnw.tamaro.events['paymentMethodChanged'].subscribe(({ data }) => {
        const { api } = data
        let eventListerIsAdded = false
        const readdExtradonationEventListener = () => {
          addExtraDonationEventHandler({ api })
        }
        if (!eventListerIsAdded) {
          eventListerIsAdded = true
          readdExtradonationEventListener({ api })
        }
        const paymentMethod = api?.paymentForm?.getPaymentMethod()
        if (gAEcommerceStepPaymentMethodTracked === false) {
          gAEcommerceStepPaymentMethodTracked = true
          track.onCheckout({
            step: 2,
            paymentMethod,
            products: gAEcommerceItems
          })
        } else {
          track.onCheckoutOption(2, {
            paymentMethod,
            products: gAEcommerceItems
          })
        }
        track.push({
          event: 'raisenowEvent',
          raisenowEventName: 'raisenowPaymentMethodChanged',
          raisenowPaymentMethod: paymentMethod
        })
      })

      rnw.tamaro.events['beforePaymentSend'].subscribe(() => {
        track.push({
          event: 'raisenowEvent',
          raisenowEventName: 'raisenowBeforeSubmit'
        })
      })

      rnw.tamaro.events['paymentComplete'].subscribe((event) => {
        const { data } = event || {}
        const { api } = data || {}
        const { epmsPaymentInfo, transactionInfo } = api || {}
        track.push({
          event: 'raisenowEvent',
          raisenowEventName: 'raisenowPaymentComplete'
        })

        const paymentStatus =
          transactionInfo?.epayment_status ?? epmsPaymentInfo?.last_status
        const forward = new Set(['success', 'succeeded'])
        const amount = transactionInfo?.amount?.toFixed(2)
        const ecommerceItems =
          gAEcommerceItems ||
          (transactionInfo?.stored_customer_ga_ecommerce_items &&
            JSON.parse(transactionInfo?.stored_customer_ga_ecommerce_items))

        if (forward.has(paymentStatus)) {
          track.onCheckout({ step: 3, products: ecommerceItems })
          track.measuringPurchase(
            ecommerceItems,
            transactionInfo?.epp_transaction_id,
            amount
          )
          onPaymentSuccess(amount, transactionInfo?.chqr_pdflink)
        } else {
          setAmountComputed(api?.paymentForm?.getAmount())
          const errorCode = transactionInfo?.payment?.error_code
            ? transactionInfo?.error_code
            : transactionInfo?.datatrans_status
          track.onCheckout({ step: 4, products: ecommerceItems, errorCode })
        }
      })
    }
  }, [initRaisnow])
  return (
    <div className="zmw-RaiseNow" id="main">
      <div className="zmw-RaiseNow_Container">
        {title && <h2 className="zmw-RaiseNow_Amount">{title}</h2>}
        {showLoading && labels.loading && (
          <>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="visually-hidden">{labels.loading}</div>
          </>
        )}
        <div className="paymentForm" />
      </div>
    </div>
  )
}
export default RaiseNowWidget
